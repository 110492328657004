import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';



function UpdaterTag(props) {
    const [totalSupply, setTotalSupply] = useState(51)
    async function getTheCount() {
        const totalSupply = await props.nuggetContract.methods.totalSupply().call()
        setTotalSupply(totalSupply)
        console.log(totalSupply)
    }
    const MINUTE_MS = 2000;
    useEffect(() => {
      const interval = setInterval(() => {

        getTheCount()



      }, MINUTE_MS);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])
    return (
        <>
            <h1>{totalSupply}/500 sold!</h1>
        </>
    )
}

export default UpdaterTag;
