import React, { useState, useEffect } from 'react';//
import Web3 from "web3";
import { Button } from './Button';
import './Mint.css';
import {ADDRESS, ABI} from "../config.js";
import whiteListMintPass from "../new_data.json";
import UpdaterTag from './Updater';







function Mint() {

    // FOR WALLET
    const [signedIn, setSignedIn] = useState(false)

    const [walletAddress, setWalletAddress] = useState(null) //

    // FOR MINTING
    const [how_many_nuggets, set_how_many_nuggets] = useState(1)
    const [how_many_nuggets_allow, set_how_many_nuggets_allow] = useState(1)

    const [nuggetContract, setNuggetContract] = useState(null)//

    // INFO FROM SMART Contract

    const [totalSupply, setTotalSupply] = useState(2)

    const [saleStarted, setSaleStarted] = useState(null)//

    const [getPrice, setNuggetPrice] = useState(0)//

    const [notInvited, setNotInvited] = useState(false)
    const [realnow, setRealNow] = useState(0)
    const [now, setNow] = useState(0);
    const [currentTime, setCurrentTime] = useState(0)
    const [totalSold, setTotalSold] = useState(0)
    const [gasNow, setGasNow] = useState(0)


    const MINUTE_MS = 2000;

    async function countitnow(){
        const newNow = Date.now()/1000;
       setNow(newNow);
       // console.log(now)
    }
    useEffect(() => {
      const interval = setInterval(() => {
        countitnow()
        setCurrentTime(Date.now()/1000)
        console.log(Date.now()/1000)
        // console.log(currentTime)

        // console.log(newNow);
        // console.log(now);

      }, MINUTE_MS);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     if(signedIn==true){
    //     getTheCount()
    //
    //     } else{
    //     console.log('nuthin');
    //     }
    //   }, MINUTE_MS);
    //
    //   return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // }, [])

//    useEffect( async() => {
//
//        signIn()
//
//    }, [])

    function signIn() {
        if (typeof window.web3 !== 'undefined') {
            // Use existing gateway
            window.web3 = new Web3(window.ethereum);
        } else {
            alert("No Ethereum interface injected into browser. Read-only access");
        }

        window.ethereum.enable()
        .then(function (accounts) {
            window.web3.eth.net.getNetworkType()
            // checks if connected network is mainnet (change this to rinkeby if you wanna test on testnet)
            .then((network) => {console.log(network);if(network != "main"){alert("You are on " + network+ " network. Change network to mainnet or you won't be able to do anything here")} });
            let wallet = accounts[0]
            setWalletAddress(wallet)
            var searchAddy2 = wallet.toLowerCase();
            try{setWhiteStatus(whiteListMintPass[searchAddy2]["address"])
            }catch (err) {
                setNotInvited(true)
            }
            setSignedIn(true)
            callContractData(wallet)
        })
        .catch(function (error) {
            // Handle error. Likely the user rejected the login
            console.error(error)
        })
    }
    //
    function signOut() {
        setSignedIn(false)
    }
    async function getTheCount() {
        const totalSupply = await nuggetContract.methods.totalSupply().call()
        setTotalSupply(totalSupply)
        console.log(totalSupply)
    }

    async function callContractData(wallet) {
        // let balance = await web3.eth.getBalance(wallet);
        // setWalletBalance(balance)
        const nuggetContract = new window.web3.eth.Contract(ABI, ADDRESS)
        setNuggetContract(nuggetContract)

        const saleUnix = await nuggetContract.methods.publicSale().call()
        // console.log("saleisActive" , salebool)
        setSaleStarted(saleUnix)
        console.log(saleUnix)



        const totalSupply = await nuggetContract.methods.totalSupply().call()
        setTotalSupply(totalSupply)
        console.log(totalSupply)

        const getPrice = await nuggetContract.methods.getPrice().call()
        setNuggetPrice(getPrice)




    }

    async function mintMintPass(how_many_nuggets) {
        if (nuggetContract) {

            try{
            const price = (Number(150000000000000000) * how_many_nuggets)
            const gasPrice = (price*4)
            console.log(price)

                const gasAmount = await nuggetContract.methods.mintETHWalkerMintPass(how_many_nuggets).estimateGas({from: walletAddress, value: price})


            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            nuggetContract.methods
                .mintETHWalkerMintPass(how_many_nuggets)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })

            }catch (error) {
                console.log('User may have insufficient funds for mint');
                console.log(error);
                alert("User may have insufficient funds for mint. Or Mint may be closed(closes July4th 2:00pm PDT)")
            }

        } else {
            console.log("Wallet not connected")
        }
    };
    const [whiteStatus, setWhiteStatus] = useState('')

    async function allowMintMintPass(how_many_nuggets_allow) {
        if (nuggetContract) {
            try{
            var searchAddy2 = walletAddress.toLowerCase()

            var whiteListMintPassUsable = JSON.stringify(whiteListMintPass)


            const newboy1 = whiteListMintPass[searchAddy2]["address"];
            const newboy2 = whiteListMintPass[searchAddy2]["index"];
            const newboy3 = whiteListMintPass[searchAddy2]["sig"];







            const price = (Number(150000000000000000) * how_many_nuggets_allow)
            const mr_index = whiteListMintPass[searchAddy2]["index"];
            const mr_signature = whiteListMintPass[searchAddy2]["sig"];
            const gasPrice = (price*4)
            console.log(price)


                const gasAmount = await nuggetContract.methods.allowlistETHWalkerMintPass(how_many_nuggets_allow, mr_index, mr_signature).estimateGas({from: walletAddress, value: price})

            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            nuggetContract.methods
                .allowlistETHWalkerMintPass(how_many_nuggets_allow, mr_index, mr_signature)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })
            }catch (error) {
                console.log('User may have insufficient funds for mint');
                console.log(error);
                alert("User may have insufficient funds for mint. Or Mint may be closed(closes July4th 2:00pm PDT)")
            }

        } else {
            console.log("Wallet not connected")
        }
    };






const handleClick = () => {
    //console.log('Hello there!');
    mintMintPass(how_many_nuggets)
};
const handleClickAllow = () => {
    //console.log('Hello there!');
    allowMintMintPass(how_many_nuggets_allow)
};
return (
    <div className="daddy">
        <div className='new-body'>
            <div className='hero-container-Private'>





        </div>






        </div>
        <div className="FAQ-container">

        {Date.now()/1000>=1656968401 ?
        <>
        <div>
        <h1>The ETHWalkers MintPass Mint is Closed</h1>
        </div>
        <h1>Verified on Etherscan:</h1>
        <p><a href="https://etherscan.io/address/0x7eca22913103e4a9d92b1ffd892b86d1906093e5" link="white" style={{color: 'white'}} vlink="red">0x7eCa22913103e4a9D92b1FfD892b86d1906093E5</a></p>
        </>
        : <div className='do-not-display'>
                <h3></h3>
            </div>
        }
        {Date.now()/1000<=1656968401 ?
        <>

        {signedIn?
            <>
            {/*}<h1>{totalSupply}/500 sold!</h1><button style={{marginTop: '-30px', marginBottom: '20px'}} onClick={getTheCount}>refresh count</button>*/}
            <UpdaterTag nuggetContract={nuggetContract}/>

            <h1>Public Mint(limit of 10 per Tx. With 500 Supply)</h1>
            <div id="mint" className="flex">


        <span className="flex">Mint</span>

        <input
            style={{color: 'black'}}
            type="number"
            min="1"
            max="10"
            value={how_many_nuggets}
            onChange={ e => set_how_many_nuggets(e.target.value) }
            name=""
        />
        <span className="flex">Passes!</span>

        </div>
        </>
        :<div className='do-not-display'></div>}
            <div className='hero-btns'>
            {signedIn?
            <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
                onClick={handleClick}
            >
            MINT {how_many_nuggets} Mint Passes for {(0.15 * how_many_nuggets)} ETH + GAS
            </Button>
            : <Button
                onClick={signIn}
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
            >Click to Connect Wallet with Metamask
            </Button>
            }
            </div>
            {notInvited?
                <>

                <p>Whitelisted wallet not connected.</p>
                </>
            : <div className='do-not-display'>
                    <h3></h3>
                </div>
            }
            {!whiteStatus ?
                <>
                <h1>Verified on Etherscan:</h1>
                <p><a href="https://etherscan.io/address/0x7eCa22913103e4a9D92b1FfD892b86d1906093E5" link="white" style={{color: 'white'}} vlink="red">0x7eCa22913103e4a9D92b1FfD892b86d1906093E5</a></p>
                </>
            : <div className='do-not-display'>
                    <h3></h3>
                </div>
            }
            </>
            : <div className='do-not-display'>
                    <h3></h3>
                </div>
            }
            </div>
            {whiteStatus ?
            <div className="white-list-sec">
            {signedIn?
                <>
                <h1>Whitelist Guaranteed Mint</h1>
                <div id="mint" className="flex">

            <span className="flex">Mint</span>

            <input
                style={{color: 'black'}}
                type="number"
                min="1"
                max="10"
                value={how_many_nuggets_allow}
                onChange={ e => set_how_many_nuggets_allow(e.target.value) }
                name=""
            />
            <span className="flex">Passes!</span>

            </div>
            </>
            :<div className='do-not-display'></div>}
            <div className='hero-btns'>
            {signedIn?
                <>

            <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
                onClick={handleClickAllow}
            >
            MINT {how_many_nuggets_allow} Mint Passes for {(0.15 * how_many_nuggets_allow)} ETH + GAS
            </Button>
            </>
            : <div className='do-not-display'>
                    <h3></h3>
                </div>
            }
            </div>
            <h1>Verified on Etherscan:</h1>
            <p><a href="https://etherscan.io/address/0x7eCa22913103e4a9D92b1FfD892b86d1906093E5" link="white" style={{color: 'white'}} vlink="red">0x7eCa22913103e4a9D92b1FfD892b86d1906093E5</a></p>
        </div>
        : <div className='do-not-display'>
                <h3></h3>
            </div>
        }
    </div>



);
}
export default Mint;
